footer{
    margin-top: 40px;
    background-color: #191920;
    position: relative;

    .footer-row{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 5px 0 10px 0px;
    }

    .footer-copyright{
        display: flex;
        justify-content: space-between;
        border-top: 1px solid $border-color;
        padding: 25px 30px 25px 35px;

        p{
            margin: 0;
        }
    }

    .footer-group ul{
        display: flex;
        list-style: none;
        padding: 0;
        margin: 0;

        li{
            padding: 55px 35px 25px 35px;
            border-right: 1px solid $border-color;
        }

        li:last-child{
            border:0px;
        }

        h4{
            margin: 0;
        }
    }

    .footer-brand{
        padding-top: 55px;
        padding-left: 35px;
    }
}

@include media-breakpoint-down(md) { 
    footer .footer-row, footer .footer-group ul{
        display: block;
        text-align: center;
    }

    footer .footer-brand{
        padding: 10px 20px;
    }

    footer .footer-group ul li{
        border:0px;
        padding: 10px 20px;
    }

    footer .footer-copyright{
        display: block;
        text-align: center;
    }

    footer .nav-social{
        justify-content: center;
        margin-top: 15px;
    }
}