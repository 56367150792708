/*!
 * Bootstrap v4.0.0 (https://getbootstrap.com)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

@import "functions";
@import "settings";
@import "variables";
@import "mixins";
@import "root";
@import "reboot";
@import "type";
@import "images";
@import "code";
@import "grid";
@import "tables";
@import "forms";
@import "buttons";
@import "transitions";
@import "dropdown";
@import "button-group";
@import "input-group";
@import "custom-forms";
@import "nav";
@import "navbar";
@import "card";
@import "breadcrumb";
@import "pagination";
@import "badge";
@import "jumbotron";
@import "alert";
@import "progress";
@import "media";
@import "list-group";
@import "close";
@import "modal";
@import "tooltip";
@import "popover";
@import "carousel";
@import "utilities";
@import "print";

/*!
 * Project:	      Torchala
 * Version:	      1.0
 * Assigned to:   Comup.pl
 */

// @import vendor
@import "vendor/swiper";
@import "vendor/animate";
@import "vendor/hamburgers/hamburgers";
@import "vendor/mfp/main";

// @import components
@import "components/icons";
@import "components/typography";
@import "components/general";
@import "components/buttons";
@import "components/header";
@import "components/swiper";
@import "components/boxed";
@import "components/sections";
@import "components/form";
@import "components/footer";
@import "components/image-holder";
@import "components/mfp";