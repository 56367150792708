/**!  Boxed  **/
.boxed{
    p{
        font-weight: 300;
        font-size: 18px;
        line-height: 20px;
        margin-bottom: 20px;
    }

    strong{
        color: #fff;
    }
}

.boxed-small{
    p{
        font-size: 14px;
    }

    h2{
        font-size: 40px;
    }

    .boxed-title{
        margin-left: -50%;
    }
}

.boxed-small.boxed-secoundary .boxed-title{
    margin-left: -40%;
}

.boxed-title{
    margin-left: -190px;
    padding-bottom: 25px;
    margin-bottom: 30px;
    position: relative;
    

    h2{
        line-height: 1;
        margin: 0;
    }

    &:after{
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 105px;
        height: 1px;
        background-color: #fff;
    }
}

.boxed-list-primary{
    list-style: none;
    padding: 0;
    margin: 0;

    li{
        position: relative;
        padding-left: 40px;

        &:after{
            content: "";
            position: absolute;
            left: 0;
            top: 3px;
            width: 15px;
            height: 15px;
            border-radius: 50%;
            background-color: $primary;
        }
    }
}

.boxed-list-secoundary{
    list-style: none;
    padding: 0;
    margin: 0;

    li{
        position: relative;
        padding-left: 25px;

        &:after{
            content: "";
            position: absolute;
            left: 0;
            top: 5px;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: #fff;
        }
    }
}

//boxed contact

.boxed.boxed-contact{
    min-height: auto;
}

.boxed-contact{
    display: flex;
    margin-left: -55%;
    width: 155%;
}

.boxed-contact .boxed-list-contact:nth-child(1){
    flex: 1 1 20%;
}

.boxed-contact .boxed-list-contact:nth-child(2){
    flex: 1 1 60%;
    margin-left: 20%;
}

.boxed-tite-contact{
    margin: 45px 0;
    h4{
        font-size: 30px;
        font-weight: 900;
    }
}


.boxed-list-contact{
    list-style: none;
    padding: 0;
    margin: 0;
    padding: 40px 0;
    li{
        position: relative;
        padding: 10px 0;
        &:after{
            content: "";
            position: absolute;
            left: 0;
            bottom: 0;
            width: 105px;
            height: 1px;
            background-color: #fff;
        }
    }
    li p{
        font-size: 14px;
        font-weight: 400;
        margin: 0;
    }
    
}

.boxed-primary-wrapper{
    position: relative;
    padding: 60px 0;
    min-height: 460px;
}

.boxed-primary{
    background-color: $primary;
    padding: 35px 80px 100px 120px;
    margin-left: 25%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
    position: relative;
    min-height: 480px;
    z-index: 4;

    strong{
        color: #fff;
    }
}

.boxed-under{
    position: absolute;
    z-index: 2;
    top: 0;
    height: 480px;
    left: 0%;
    width: 65%;
    background-color: $boxed-bg;
}

.boxed-secoundary-wrapper{
    padding: 120px 0;
}

.boxed-pt-0{
    padding-top: 0;
}

.boxed-secoundary-bg{
    background-color: $boxed-bg;
}

.boxed-secoundary{
    padding: 120px 80px 120px 120px;
    margin-left: 25%;
    position: relative;
}

.btn-boxed{
    position: absolute;
    right: 20px;
    bottom: 0;
    transform: translateY(50%);
}