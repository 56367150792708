/**!  Typography **/

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    font-family: 'Roboto', sans-serif;
    color: #fff;
}

h1,
.h1 {}

h2,
.h2 {
    font-size: 72px;
    font-weight: 900;
}

h3,
.h3 {
    font-size: 50px;
}

h4,
.h4 {
    font-size: 22px;
}

h5,
.h5 {
    font-size: 20px;
}

h6,
.h6 {
    font-size: 18px;
}

p {
    color: #fff;
    font-size: 13px;
}

a {
    color: inherit;
}

strong {
    font-weight: 700;
}

a,
a:hover,
a:focus,
a:active {
    text-decoration: none;
}

@include media-breakpoint-down(xl) { 
    h2,
    .h2 {
        font-size: 54px;
        font-weight: 900;
    }
    h3,
    .h3 {
        font-size: 36px;
    }
    h4,
    .h4 {
        font-size: 20px;
    }
    h5,
    .h5 {
        font-size: 20px;
    }
    h6,
    .h6 {
        font-size: 18px;
    }
}


@include media-breakpoint-down(sm) { 
    h2,
    .h2 {
        font-size: 36px;
        font-weight: 900;
    }
    h3,
    .h3 {
        font-size: 30px;
    }
    h4,
    .h4 {
        font-size: 20px;
    }
    h5,
    .h5 {
        font-size: 20px;
    }
    h6,
    .h6 {
        font-size: 18px;
    }
}