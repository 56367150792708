.form-control {
    display: block;
    width: 100%;
    padding: 15px;
    font-size: 12px;
    line-height: 1.5;
    background-color: $primary;
    background-clip: padding-box;
    border: 1px solid $primary;
    color: #fff;
    border-radius: 0px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-control:focus{
    box-shadow: none;
    border-color: $secoundary;
    color: #fff;
}

form{
    margin-left: 25%;
    width: 67%;
}

.form-row {
    margin-right: -10px;
    margin-left: -10px;
}

.form-row > .col, .form-row > [class*="col-"] {
    padding-right: 10px;
    padding-left: 10px;
}


@include media-breakpoint-down(md) { 
    form{
        margin-left: 0;
        width: 100%;
    }
}