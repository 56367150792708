/**!  Navs **/
.nav-social{
    .nav-link{
        color: #fff;
        font-size: 24px;
        padding: 4px 5px; 
    }
    .nav-link:hover, .nav-link:focus{
        color: $primary;
    }
}

/**!  Brand **/
.brand{
    text-transform: uppercase;
    font-weight: 900;
    color: #fff;
}


/**!  Header **/
header{
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    display: block;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.65);
   
}

.header-row{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header-group{
    display: flex;
    align-items: center;
}

.nav-main{
    padding: 20px 0;
    position: relative;
    .nav-link{
        color: #fff;
        font-size: 14px;
        font-weight: 700;
        padding-top: 15px;
        padding-bottom: 15px;
        text-transform: uppercase;
        transition: all 0.3s;
    }

    .nav-link:hover{
        color: $primary;
    }
}

.header-mobile{
    display: none;
}

.header-phone{
    color: #fff;
    font-weight: 700;
    font-size: 24px;
}


@include media-breakpoint-down(md) { 
    header{
        transition: all 0.3s;
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: #22222a;
        z-index: 999;
        display: block;
        transform: translate3d(100%, 0, 0);
       
        .brand{
            display: none;
        }
        .header-brand{
            display: none;
        }

        .header-row{
            display: flex;
            flex-direction: column;
            min-height: 90vh;
            align-items: center;
            justify-content: center;
        }

        .nav-main{
            display: block;
            text-align: center;
        }

        .nav-main:before{
            content: none;
        }

        .header-group{
            justify-content: center;
        }

        .nav-main .dropdown-menu{
            position: static;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            transform: translateX(0%)!important; 
            display: none;
        }

        .nav-main .dropdown-menu.show{
            display: block;
        }
    }

    .header-mobile{
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        z-index: 9999;
        background-color: #000;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 8px 20px;

        h4{
            margin: 0;
            line-height: 1;
        }

        .header-brand{
            padding-left: 0;
        }
        #toggleNav{
            outline: 0px!important;
        }
    }

    header.show{
        transform: translate3d(0%, 0, 0);
    }
}
