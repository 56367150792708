/**!  Buttons  **/
.btn{
    color: #fff;
    font-size: 11px;
    font-weight: 400;
    text-transform: uppercase;
    border-radius: 0px;
    padding: 20px 30px;
    text-align: center;
    min-width: 170px
}

.btn-primary:hover, .btn-primary:focus, .btn-primary.focus{
    color: #fff;
    box-shadow: none;
}

.btn-secoundary:hover, .btn-secoundary:focus, .btn-secoundary.focus{
    color: #fff;
    box-shadow: none;
}
