@font-face {
    font-family: 'icomoon';
    src: url(../fonts/icomoon.eot?1cobi);
    src: url(../fonts/icomoon.eot?1cobi#iefix) format('embedded-opentype'), url(../fonts/icomoon.ttf?1cobi) format('truetype'), url(../fonts/icomoon.woff?1cobi) format('woff'), url(../fonts/icomoon.svg?1cobi#icomoon) format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .icon-arrow_back:before {
    content: "\e5c4";
  }
  .icon-arrow_downward:before {
    content: "\e5db";
  }
  .icon-arrow_drop_down:before {
    content: "\e5c5";
  }
  .icon-arrow_drop_up:before {
    content: "\e5c7";
  }
  .icon-arrow_forward:before {
    content: "\e5c8";
  }
  .icon-arrow_upward:before {
    content: "\e5d8";
  }
  .icon-close:before {
    content: "\e5cd";
  }
  .icon-clear_all:before {
    content: "\e0b8";
  }
  .icon-expand_less:before {
    content: "\e5ce";
  }
  .icon-expand_more:before {
    content: "\e5cf";
  }
  .icon-keyboard_arrow_down:before {
    content: "\e313";
  }
  .icon-keyboard_arrow_left:before {
    content: "\e314";
  }
  .icon-keyboard_arrow_right:before {
    content: "\e315";
  }
  .icon-keyboard_arrow_up:before {
    content: "\e316";
  }
  .icon-plus:before {
    content: "\ea0a";
  }
  .icon-minus:before {
    content: "\ea0b";
  }
  .icon-cross:before {
    content: "\ea0f";
  }
  .icon-facebook2:before {
    content: "\ea90";
  }
  .icon-facebook22:before {
    content: "\ea91";
  }
  .icon-instagram2:before {
    content: "\ea92";
  }
  .icon-whatsapp:before {
    content: "\ea93";
  }
  .icon-spotify:before {
    content: "\ea94";
  }
  .icon-telegram:before {
    content: "\ea95";
  }
  .icon-twitter2:before {
    content: "\ea96";
  }
  .icon-youtube:before {
    content: "\ea9d";
  }
  .icon-youtube2:before {
    content: "\ea9e";
  }
  .icon-vimeo:before {
    content: "\eaa0";
  }
  .icon-vimeo2:before {
    content: "\eaa1";
  }
  .icon-tumblr:before {
    content: "\eab9";
  }
  .icon-tumblr2:before {
    content: "\eaba";
  }
  .icon-linkedin:before {
    content: "\eac9";
  }
  .icon-linkedin2:before {
    content: "\eaca";
  }
  .icon-pinterest:before {
    content: "\ead1";
  }
  .icon-pinterest2:before {
    content: "\ead2";
  }
  .icon-file-pdf:before {
    content: "\eadf";
  }
  .icon-file-openoffice:before {
    content: "\eae0";
  }
  .icon-file-word:before {
    content: "\eae1";
  }
  .icon-file-excel:before {
    content: "\eae2";
  }
  .icon-libreoffice:before {
    content: "\eae3";
  }
  .icon-aplikacje-mobilne:before {
    content: "\e900";
  }
  .icon-arrow-down:before {
    content: "\e901";
  }
  .icon-arrow-left:before {
    content: "\e902";
  }
  .icon-arrow-right:before {
    content: "\e903";
  }
  .icon-arrow-up:before {
    content: "\e904";
  }
  .icon-centrala-chmura:before {
    content: "\e905";
  }
  .icon-chat:before {
    content: "\e906";
  }
  .icon-dedykowana-numeracja:before {
    content: "\e907";
  }
  .icon-doswiadczenie:before {
    content: "\e908";
  }
  .icon-facebook:before {
    content: "\e909";
  }
  .icon-hourglass:before {
    content: "\e90a";
  }
  .icon-innowacje:before {
    content: "\e90b";
  }
  .icon-instagram:before {
    content: "\e90c";
  }
  .icon-koszty:before {
    content: "\e90d";
  }
  .icon-masowa-wysylka:before {
    content: "\e90e";
  }
  .icon-stationery:before {
    content: "\e90f";
  }
  .icon-telephone:before {
    content: "\e910";
  }
  .icon-tr-down:before {
    content: "\e911";
  }
  .icon-tr-left:before {
    content: "\e912";
  }
  .icon-tr-right:before {
    content: "\e913";
  }
  .icon-tr-up:before {
    content: "\e914";
  }
  .icon-twitter:before {
    content: "\e915";
  }
  .icon-zarzadzanie:before {
    content: "\e916";
  }
  